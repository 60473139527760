import { render, staticRenderFns } from "./CandleSamples.vue?vue&type=template&id=4c839709&scoped=true"
import script from "./CandleSamples.vue?vue&type=script&lang=js"
export * from "./CandleSamples.vue?vue&type=script&lang=js"
import style0 from "./CandleSamples.vue?vue&type=style&index=0&id=4c839709&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c839709",
  null
  
)

export default component.exports