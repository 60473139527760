<template>
  <div class="flex flex-col gap-2">
    <div class="flex gap-2">
      <b-btn
        v-if="isAdmin()"
        variant="primary"
        size="sm"
        @click="openCreateModal"
        class="mt-2"
      >
        New test
      </b-btn>

       <b-btn
        v-if="isAdmin()"
        variant="white"
        size="sm"
        @click="openReportModal"
        class="mt-2"
      >
        Report
      </b-btn>

    </div>
    <div v-if="samples">
      <div v-if="samples.length === 0">
        No tests available
      </div>
      <div>
        <div class="flex flex-col gap-2 max-w-lg">
          <b-btn
            v-for="sample in samples"
            :key="`${sample.type}_${sample.id}`"
            variant="white"
            size="sm"
            @click="openSampleModal(sample.type, sample.id)"
            class="sample"
          >
            <div class="status" :class="[ `bg-${sample.status_color}` ]"></div>
            <div class="flex items-center gap-2 font-normal text-nowrap">
              <span class="w-20">
                <b-badge variant="dark" class="w-full">{{ sample.title }}</b-badge>
              </span>
              <div class="flex gap-2 justify-between w-full">
                 <span>
                   <span>{{ sample.text }}</span>
                   <span
                    v-if="sample.assignee"
                    class="ml-2"
                  >
                    ({{ sample.assignee }})
                  </span>
                 </span>
                 <span class="w-16 text-right">{{ sample.date }}</span>
              </div>
            </div>
          </b-btn>
        </div>
      </div>

    </div>
    <div v-else-if="isLoading">
      Loading..
    </div>
    <div v-else>
      Tests unavailable
    </div>

    <create-modal
      v-if="modals.create.showModal"
      :data="modals.create"
      @create="createSample"
    />

    <report-modal
      v-if="modals.report.showModal"
      :data="modals.report"
      :id="id"
    />

    <burntest-modal
      v-if="modals.burntest.showModal"
      :data="modals.burntest"
      :candle_id="id"
      @updated="fetchSamples"
    />

    <wicktest-modal
      v-if="modals.wicktest.showModal"
      :data="modals.wicktest"
      :candle_id="id"
      @updated="fetchSamples"
    />
  </div>
</template>

<script>
const ReportModal = () => import('@/components/candle/sample/ReportModal.vue');
const CreateModal = () => import('@/components/candle/sample/CreateModal.vue');
const BurntestModal = () => import('@/components/candle/sample/BurntestModal.vue');
const WicktestModal = () => import('@/components/candle/sample/WicktestModal.vue');

export default {
  name: 'CandleSamples',
  components: {
    ReportModal,
    CreateModal,
    BurntestModal,
    WicktestModal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      samples: null,
      modals: {
        create: {
          showModal: false,
        },
        report: {
          showModal: false,
        },
        burntest: {
          showModal: false,
          id: null,
        },
        wicktest: {
          showModal: false,
          id: null,
        },
      },
    };
  },
  methods: {
    fetchSamples() {
      this.samples = null;
      this.loadingCount++;
      this.$http
        .get(`/candle/${this.id}/samples`)
        .then((res) => {
          this.samples = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch samples: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    openCreateModal() {
      this.modals.create.showModal = true;
    },
    openReportModal() {
      this.modals.report.showModal = true;
    },
    openSampleModal(type, id) {
      this.modals[type].showModal = true;
      this.modals[type].id = id;
    },
    createSample(type) {
      this.loadingCount++;
      this.$http
        .post(`/candle/${this.id}/samples/${type}`)
        .then((res) => {
          this.openSampleModal(type, res.body);
          this.fetchSamples();
        })
        .catch((err) => {
          this.$toast.error(`Failed to create sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.fetchSamples();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sample {
  position: relative;
}
.sample .status {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: red;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
